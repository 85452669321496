import React from "react"

import telegramSvg from "../../../images/social/RDSvg/Telegram.svg"
import twitterSvg from "../../../images/social/RDSvg/twitter.svg"
import discordSvg from "../../../images/social/RDSvg/discord.svg"
import redditSvg from "../../../images/social/RDSvg/reddit.svg"
import cmcSvg from "../../../images/social/RDSvg/cmc.svg"
import coingeckoSvg from "../../../images/social/RDSvg/coingecko.svg"
import telegramSvgHover from "../../../images/social/RDSvg/Telegramhover.svg"
import twitterSvgHover from "../../../images/social/RDSvg/twitterhover.svg"
import discordSvgHover from "../../../images/social/RDSvg/discordhover.svg"
import redditSvgHover from "../../../images/social/RDSvg/reddithover.svg"
import cmcSvgHover from "../../../images/social/RDSvg/cmchover.svg"
import coingeckoSvgHover from "../../../images/social/RDSvg/coingeckohover.svg"
import HoverImage from "react-hover-image"

const social = [
  {
    name: "Telegram",
    href: "https://t.me/odinprotocol",
    icon: telegramSvg,
    iconhover: telegramSvgHover,
  },
  {
    name: "Twitter",
    href: "https://twitter.com/odinprotocol",
    icon: twitterSvg,
    iconhover: twitterSvgHover,
  },
  {
    name: "Discord",
    href: "https://discord.gg/BrBfcgmnk2",
    icon: discordSvg,
    iconhover: discordSvgHover,
  },
  {
    name: "Reddit",
    href: "https://www.reddit.com/r/OdinProtocol",
    icon: redditSvg,
    iconhover: redditSvgHover,
  },
  {
    name: "CMC",
    href: "https://coinmarketcap.com/currencies/odin-protocol/",
    icon: cmcSvg,
    iconhover: cmcSvgHover,
  },
  {
    name: "CoinGecko",
    href: "https://coinmarketcap.com/currencies/odin-protocol/",
    icon: coingeckoSvg,
    iconhover: coingeckoSvgHover,
  },
]

export default function SocialLinks() {
  return (
    <div className="flex justify-center space-x-3 md:order-2">
      {social.map(item => (
        <a
          key={item.name}
          title={item.name}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          // className="hover:bg-borderSecondary h-5"
        >
          <HoverImage
            className="h-5 w-5 mx-1"
            src={item.icon}
            hoverSrc={item.iconhover}
          />
        </a>
      ))}
    </div>
  )
}
