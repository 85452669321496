import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { keyframes } from "styled-components"
import ODIN_ICON from "../../../images/icon_3.svg"
import ODIN_ICON_ORIGINAL from "../../../images/icon_1.svg"
import ODIN_YELLOW_ICON from "../../../images/icon_2.svg"
import Banner from "../elementsRD/Banner"
import Footer from "./Footer"
import Header from "./Header"
import Cta from "../sectionsRD/Cta"
import "../../../styles/layout.css"

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

const Text = styled.p`
  font-weight: 600;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) -10%,
    rgba(255, 255, 255, 1) 50%
  );
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: ${gradientAnimation} 2s ease-in-out infinite;
`

const Progress = styled.h2`
  font-weight: 600;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) -10%,
    rgba(255, 255, 255, 1) 50%
  );
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
`

const BgLine = styled.div`
  width: 2px;
  height: 100vh;
  opacity: 8%;
  background: linear-gradient(to bottom, #ffffff, #000000);
  position: absolute;
`

export default function Layout({ children, loaderDone, loading, progress }) {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false)
  const drawerRef = useRef(null)

  // Disable/Enable scrolling when Drawer opened
  useEffect(() => {
    const handleClickOutside = event => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setIsDrawerOpened(false)
      }
    }

    const handleResize = () => {
      if (window.innerWidth > 1023) {
        setIsDrawerOpened(false)
      }
    }

    if (isDrawerOpened) {
      document.body.style.overflowY = "hidden"
      document.addEventListener("click", handleClickOutside)
      window.addEventListener("resize", handleResize)
    } else {
      document.body.style.overflowY = "visible"
      document.removeEventListener("click", handleClickOutside)
      window.removeEventListener("resize", handleResize)
    }

    return () => {
      document.removeEventListener("click", handleClickOutside)
      window.removeEventListener("resize", handleResize)
    }
  }, [isDrawerOpened])

  return (
    <>
      {loading && (
        <div className="bg-[#000] w-full relative h-screen text-white">
          <div className="absolute p-10 text-center top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 flex flex-col items-center">
            <div className="relative">
              <img
                src={ODIN_ICON}
                alt="Gray SVG"
                style={{
                  clipPath: `inset(${progress * 100}% 0 0)`,
                  transition: "clip-path 2s ease-in-out",
                }}
                // className="rotate-180"
                className="w-20 h-20 lg:w-32 lg:h-32 mb-2 lg:mb-4 rotate-180"
                // className={`w-20 h-20 lg:w-32 lg:h-32 mb-2 lg:mb-4 rotate-180 ${
                //   loaderDone && "odinIconAnimation"
                // }`}
              />
              <img
                src={ODIN_YELLOW_ICON}
                alt="Yellow SVG"
                style={{
                  clipPath: `inset(${100 - progress * 100}% 0 0)`,
                  transition: "clip-path 2s ease-in-out",
                }}
                // className="absolute top-0"
                className="w-20 h-20 lg:w-32 lg:h-32 mb-2 lg:mb-4 absolute top-0"
                // className={`w-20 h-20 lg:w-32 lg:h-32 mb-2 lg:mb-4 absolute top-0 ${
                //   loaderDone && "odinIconAnimation"
                // }`}
              />
            </div>
            {/* <Progress className="text-2xl lg:text-5xl mb-2 lg:mb-4"> */}
            <Progress
              className={`text-2xl lg:text-5xl mb-2 lg:mb-4 ${
                loaderDone && "hidden"
              }`}
            >
              {progress < 50 ? "" : progress < 100 ? "5" : "10"}0%
            </Progress>
            {/* <Text className="opacity-50 text-xs lg:text-2xl">Loading...</Text> */}
            <Text
              className={`opacity-50 text-xs lg:text-2xl ${
                loaderDone && "hidden"
              }`}
            >
              Loading...
            </Text>
          </div>
        </div>
      )}

      {!loading && (
        <>
          {/* <div className="absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4 z-20">
            <img
              src={ODIN_ICON_ORIGINAL}
              alt="Yellow SVG"
              // className="w-20 h-20 lg:w-32 lg:h-32 mb-2 lg:mb-4 absolute top-0"
              className={`w-20 h-20 lg:w-32 lg:h-32 mb-2 lg:mb-4 absolute top-0 ${
                loaderDone && "odinIconAnimation"
              }`}
              style={{
                clipPath: `inset(0% 0 0)`,
                transition: "clip-path 2s ease-in-out",
              }}
            />
          </div> */}

          <div className="w-full h-full min-h-screen mx-auto font-customRegular text-textSecondary p-3 lg:px-6 lg:py-3 bg-[#111111]">
            {/* <Header /> */}
            <Header
              isDrawerOpened={isDrawerOpened}
              setIsDrawerOpened={setIsDrawerOpened}
              drawerRef={drawerRef}
            />
            <div
              className={`mx-auto relative Container-big-screen ${
                isDrawerOpened && "blur-md"
              }`}
            >
              {/* <Banner /> */}
              <div className="px-4 sm:px-6 lg:px-4 xl:px-0">
                <BgLine className="Line_1" style={{ left: "2%" }}></BgLine>
                <BgLine className="Line_2" style={{ left: "25%" }}></BgLine>
                <BgLine className="Line_3" style={{ left: "50%" }}></BgLine>
                <BgLine className="Line_4" style={{ left: "75%" }}></BgLine>
                <BgLine className="Line_5" style={{ left: "98%" }}></BgLine>
                <main>{children}</main>
              </div>
            </div>
            <Cta />
            <Footer />
          </div>
        </>
      )}
    </>
  )
}
