import * as React from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
// import Layout from "../components/layout/Layout"
// import Seo from "../components/layout/Seo"
// import Hero from "../components/sections/Hero"
// import Partners from "../components/sections/Partners"
// import OdinToken from "../components/sections/OdinToken"
// //import TokenDistribution from "../components/sections/TokenDistribution"//
// import Architecture from "../components/sections/Architecture"
// import Wallace from "../components/sections/Wallace"
// import UseCases from "../components/sections/UseCases"
// import Roadmap from "../components/sections/Roadmap"
// //import Team from "../components/sections/Team"//
// import Validators from "../components/sections/Validators"
// import Statistics from "../components/sections/Statistics"
// import PartnersAndEcosystem from "../components/sections/PartnersAndEcosystem"
import { motion, useScroll } from "framer-motion"
import Layout from "../components/ReDesigned/layoutRD/Layout"
import Seo from "../components/layout/Seo"
import Hero from "../components/ReDesigned/sectionsRD/Hero"
import About from "../components/ReDesigned/sectionsRD/About"
import StakingOdin from "../components/ReDesigned/sectionsRD/StakingOdin"
import Potentials from "../components/ReDesigned/sectionsRD/Potentials"
import Mission from "../components/ReDesigned/sectionsRD/Mission"
import CoreFunctionality from "../components/ReDesigned/sectionsRD/CoreFunctionality"
import MainnetToken from "../components/ReDesigned/sectionsRD/MainnetToken"
import Roadmap from "../components/ReDesigned/sectionsRD/Roadmap"
import Ecosystem from "../components/ReDesigned/sectionsRD/Ecosystem"
import { useEffect, useState } from "react"
import Aos from "aos"
import "aos/dist/aos.css"
// import Header from "../components/ReDesigned/layoutRD/Header"

export default function Home() {
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loaderDone, setLoaderDone] = useState(false)

  useEffect(() => {
    Aos.init()
  }, [])

  // INtro loader process
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        const newProgress = prevProgress + 1
        if (newProgress >= 101) {
          clearInterval(interval)
          setTimeout(() => {
            setLoaderDone(true)
            // localStorage.setItem("progress", true)
            setLoading(false)
          }, 1200)
        }
        return newProgress
      })
    }, 20)

    return () => clearInterval(interval)
  }, [])

  // Split Text
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const splitWords = selector => {
      const elements = document.querySelectorAll(selector)

      elements.forEach(function (el) {
        el.dataset.splitText = el.textContent
        el.innerHTML = el.textContent
          .split(/\s/)
          .map(function (word) {
            return word
              .split("-")
              .map(function (word) {
                return '<span class="word ml-1">' + word + "</span>"
              })
              .join('<span class="hyphen">-</span>')
          })
          .join('<span class="whitespace"> </span>')
      })
    }

    const getLines = el => {
      var lines = []
      var line
      var words = el.querySelectorAll("span")
      var lastTop
      for (var i = 0; i < words.length; i++) {
        var word = words[i]
        if (word.offsetTop != lastTop) {
          if (!word.classList.contains("whitespace")) {
            lastTop = word.offsetTop

            line = []
            lines.push(line)
          }
        }
        line.push(word)
      }
      return lines
    }

    const splitLines = selector => {
      const elements = document.querySelectorAll(selector)
      splitWords(selector)

      elements.forEach(function (el) {
        const lines = getLines(el)
        const wrappedLines = lines
          .map(function (wordsArr) {
            return (
              '<span class="line"><span class="words custom-gradient-text">' +
              wordsArr.map(word => word.outerHTML).join("") +
              "</span></span>"
            )
          })
          .join("")
        el.innerHTML = wrappedLines
      })
    }

    splitLines(".reveal-text")

    const revealText = document.querySelectorAll(".reveal-text")

    revealText.forEach(element => {
      const lines = element.querySelectorAll(".words")

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          toggleActions: "restart none none reset",
        },
      })
      tl.set(element, { autoAlpha: 1 })
      tl.from(lines, 1, {
        yPercent: 100,
        ease: "power3.out",
        stagger: 0.25,
        delay: 0.2,
      })
    })

    ScrollTrigger.refresh()
  }, [])

  const { scrollYProgress } = useScroll()
  return (
    <>
      <motion.div
        className="progressBarMotion"
        style={{ scaleX: scrollYProgress }}
      />
      {/* <Layout>
        <Seo title="Home" />
        <Hero />
        <Statistics />
        <Partners />
        <OdinToken />//commented
        <Architecture />//commented
        <Wallace />//commented
        <PartnersAndEcosystem />//commented
        <UseCases />
        <Roadmap />
        <Validators />//commented
      </Layout> */}

      {/* Re Designed Components */}
      <Layout loaderDone={loaderDone} loading={loading} progress={progress}>
        <Seo title="Home" />
        <Hero loaderDone={loaderDone} />
        <About isSplitting={true} />
        <StakingOdin isSplitting={true} />
        <Potentials isSplitting={true} />
        <Mission isSplitting={true} />
        <CoreFunctionality isSplitting={true} />
        <MainnetToken isSplitting={true} />
        <Roadmap isSplitting={true} />
        <Ecosystem isSplitting={true} />
      </Layout>
    </>
  )
}
