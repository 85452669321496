import React, { useEffect } from "react"
import styled from "styled-components"
import RIGHT_ARROW_ICON from "../../../images/Others/RDOtherSvg/right-arrow.svg"
import RIGHT_ARROW_HOVER_ICON from "../../../images/Others/RDOtherSvg/right-arrowhover.svg"

const MissionParagraph = styled.p`
  font-size: 22px;
  line-height: 30.8px;
  width: 469px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 19.6px;
    width: 100%;
    margin: 24px 0;
  }
`

const MissionParagraphSecond = styled.p`
  font-size: 44px;
  line-height: 57.2px;
  font-weight: 600;
  width: 649px;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) -10%,
    rgba(255, 255, 255, 1) 50%
  );
  -webkit-background-clip: text;
  background-clip: text;

  @media screen and (max-width: 768px) {
    font-size: 17px;
    line-height: 26px;
    width: 100%;
  }
`

const Heading = styled.h3`
  width: 555px;
  font-size: 44px;
  color: transparent;
  background: linear-gradient(75deg, #282828, #f7f7f7, #ffffff);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    font-size: 33px;
    width: 100%;
  }
`

function Mission() {
  return (
    <section className="py-24 md:py-40" id="mission">
      <Heading className="text-left font-customHeading sm:leading-normal">
        <span className="reveal-text">
          {"Beyond Data: Zero-Party AI Revolution".split(" ").map((word, i) => (
            <span className="word" key={i}>
              {word}{" "}
            </span>
          ))}
        </span>
      </Heading>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="order-2 lg:order-1">
          <MissionParagraph className="opacity-40">
            <span className="reveal-text">
              {`Catalyze the growth of a web3-based user-centric trustless economy.
                Decentralization and transparency in AI solutions that utilize
                user-generated data. Bring transparency to the data market by
                tokenizing one of the largest real-world assets-data. Secure and
                privacy-preserving data exchanges across applications. Utilize
                Cosmos cross-chain ecosystem for enhanced network interoperability.`
                // .split(" ")
                .split(/\s+/)
                .map((word, i) => (
                  <span className="word" key={i}>
                    {word}{" "}
                  </span>
                ))}
            </span>
          </MissionParagraph>
        </div>
        <div className="order-1 lg:order-2 md:mb-8 lg:m-0 lg:flex lg:justify-end">
          <MissionParagraphSecond>
            <span className="reveal-text">
              {" Our mission is to empower users with ownership and control over their personal data is transforming the landscape of data and AI by championing a decentralized approach to zero-party data."
                .split(" ")
                .map((word, i) => (
                  <span className="word" key={i}>
                    {word}{" "}
                  </span>
                ))}
            </span>
          </MissionParagraphSecond>
        </div>
      </div>
      <div className="w-full md:mt-8 lg:mt-16">
        <a
          href="https://scan.odinprotocol.io/validators"
          target="_blank"
          rel="noopener noreferrer"
          className="border lg:absolute startBtn py-3 px-6 text-sm font-semibold uppercase text-black bg-[yellow] flex items-center justify-between hover:text-[yellow] hover:border-[yellow] hover:bg-black"
          style={{
            borderRadius: "53px",
            height: "41px",
            width: "170px",
            right: "475px",
          }}
        >
          Stake Odin
          <img src={RIGHT_ARROW_ICON} className="h-5 w-5 ms-3 icon" alt="" />
          <img
            src={RIGHT_ARROW_HOVER_ICON}
            className="h-5 w-5 ms-3 hoverIcon"
            alt=""
          />
        </a>
      </div>
    </section>
  )
}

export default Mission
