import React from "react"
import styled from "styled-components"
import ODIN_ICON from "../../../images/icon_1.svg"
import RIGHT_ARROW_ICON from "../../../images/Others/RDOtherSvg/right-arrow.svg"
import RIGHT_ARROW_HOVER_ICON from "../../../images/Others/RDOtherSvg/right-arrowhover.svg"
import GROUP_LIGHT_ICON from "../../../images/Others/RDOtherSvg/Group_light-pink.png"
import CHAIN_ICON from "../../../images/Others/RDOtherSvg/Chain.png"
import WALLET_ALT_ICON from "../../../images/Others/RDOtherSvg/Wallet_alt_light-pink.png"
import TRANSGER_ICON from "../../../images/Others/RDOtherSvg/Transger_light-pink.png"

const OdinImage = styled.img`
  width: 38px;
  height: 38px;

  @media screen and (max-width: 768px) {
    width: 27px;
    height: 27px;
  }
`

const Heading = styled.h3`
  width: 307px;
  font-size: 44px;
  line-height: 57.2px;
  font-weight: 600;
  display: inline-block;
  color: transparent;
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) -10%,
    rgba(255, 255, 255, 1) 50%
  );
  -webkit-background-clip: text;
  background-clip: text;

  @media screen and (max-width: 768px) {
    font-size: 34px;
    line-height: 40.8px;
    width: 100%;
    margin-bottom: 20px;
  }
`

const PotentialsContainer = styled.div`
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: transparent;
  border-image: linear-gradient(
      180deg,
      rgba(40, 40, 40, 0.8519782913165266) 100%,
      rgba(255, 255, 255, 0) 0%
    )
    1;
`

function MainnetToken() {
  return (
    <section className="py-20 md:py-40" id="mainnet-token">
      <div className="w-full py-12 grid grid-cols-1 md:grid-cols-2">
        <div className="mb-10">
          <div className="mb-10 flex">
            <Heading className="inline-block">
              <OdinImage
                src={ODIN_ICON}
                className="inline-block -mt-2"
                data-aos="fade-up"
                data-aos-duration="800"
              />
              <span className="reveal-text">
                {"DIN Mainnet token utilities".split(" ").map((word, i) => (
                  <span className="word" key={i}>
                    {word}{" "}
                  </span>
                ))}
              </span>
            </Heading>
          </div>
          <a
            href="https://scan.odinprotocol.io/validators"
            target="_blank"
            rel="noopener noreferrer"
            className="border startBtn py-2 px-6 mt-8 md:mt-16 text-xs uppercase text-black bg-[yellow] flex items-center justify-between hover:text-[yellow] hover:border-[yellow] hover:bg-black "
            style={{ borderRadius: "53px", height: "41px", width: "150px" }}
          >
            Start Now
            <img src={RIGHT_ARROW_ICON} className="h-5 w-5 ms-3 icon" alt="" />
            <img
              src={RIGHT_ARROW_HOVER_ICON}
              className="h-5 w-5 ms-3 hoverIcon"
              alt=""
            />
          </a>
        </div>
        <div className="w-full grid grid-cols-2">
          <PotentialsContainer className="px-3 py-4 md:px-12 md:py-8">
            <div className="flex flex-col justify-between">
              <img src={WALLET_ALT_ICON} height={28} width={28} alt="" />
              <h4 className="text-sm md:text-xl mt-2 mb-5 font-semibold">
                transaction fees
              </h4>
            </div>
          </PotentialsContainer>
          <PotentialsContainer className="px-3 py-4 md:px-12 md:py-8">
            <div className="flex flex-col justify-between">
              <img src={GROUP_LIGHT_ICON} height={28} width={28} alt="" />
              <h4 className="text-sm md:text-xl mt-2 mb-5 font-semibold">
                Become validators
              </h4>
            </div>
          </PotentialsContainer>
          <PotentialsContainer className="px-3 py-4 md:px-12 md:py-8">
            <div className="flex flex-col justify-between">
              <img src={TRANSGER_ICON} height={28} width={28} alt="" />
              <h4 className="text-sm md:text-xl mt-2 mb-5 font-semibold">
                Pay for data received from the network
              </h4>
            </div>
          </PotentialsContainer>
          <PotentialsContainer className="px-3 py-4 md:px-12 md:py-8">
            <div className="flex flex-col justify-between">
              <img src={CHAIN_ICON} height={28} width={28} alt="" />
              <h4 className="text-sm md:text-xl mt-2 mb-5 font-semibold">
                Delegate & stake
              </h4>
            </div>
          </PotentialsContainer>
        </div>
      </div>
    </section>
  )
}

export default MainnetToken
