import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { BeatLoader } from "react-spinners"
import RIGHT_ARROW_ICON from "../../../images/Others/RDOtherSvg/right-arrow.svg"
import RIGHT_ARROW_HOVER_ICON from "../../../images/Others/RDOtherSvg/right-arrowhover.svg"
import BOXES_LIGHT_IMAGE from "../../../images/Others/RDOtherSvg/Boxes_light.png"
import GROUP_IMAGE from "../../../images/Others/RDOtherSvg/Group_light.png"
import SORT_ARROW_LIGHT_IMAGE from "../../../images/Others/RDOtherSvg/Sort_arrow_light.png"
import SPEED_LIGHT_IMAGE from "../../../images/Others/RDOtherSvg/Speed_light.png"
import TRANSGER_IMAGE from "../../../images/Others/RDOtherSvg/Transger_light.png"
import WALLET_ALT_IMAGE from "../../../images/Others/RDOtherSvg/Wallet_alt_light.png"
import "../../../styles/RDStyles/custom.css"
import axios from "axios"

const Heading = styled.h3`
  width: 572px;
  font-size: 44px;
  color: transparent;
  background: linear-gradient(75deg, #282828, #f7f7f7, #ffffff);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;

  @media screen and (max-width: 768px) {
    font-size: 33px;
    width: 100%;
  }
`

const StakeSpan = styled.span`
  font-size: 32px;
  line-height: 32px;
  color: #01eb81;

  @media screen and (max-width: 1120px) {
    font-size: 14px;
  }
`

const StakeCard = styled.div`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 22px;
    padding: 2px;
    background: linear-gradient(
      180deg,
      rgba(40, 40, 40, 0.8519782913165266) 45%,
      rgba(255, 255, 255, 0) 60%
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`

const StakeP = styled.p`
  font-size: 12px;
  color: #01eb81;

  @media screen and (max-width: 768px) {
    font-size: 8px;
  }
`

const stakeDataOne = [
  { stakeKey: "[bonded coins]", stakeValue: "65.71%" },
  { stakeKey: "[Inflation]", stakeValue: "12%" },
  { stakeKey: "[APR]", stakeValue: "0.00%" },
]

const stakeDataTwo = [
  {
    stakeKey: "[latest block]",
    stakeValue: "13417880",
    stakeSrc: BOXES_LIGHT_IMAGE,
  },
  {
    stakeKey: "[Total Supply]",
    stakeValue: "145.1M",
    stakeSrc: SORT_ARROW_LIGHT_IMAGE,
  },
  {
    stakeKey: "[transaction(s)]",
    stakeValue: "0",
    stakeSrc: TRANSGER_IMAGE,
  },
]

const stakeDataThree = [
  {
    stakeKey: "[block speed]",
    stakeValue: "2.187s",
    stakeSrc: SPEED_LIGHT_IMAGE,
  },
  {
    stakeKey: "[active validators]",
    stakeValue: "34",
    stakeSrc: GROUP_IMAGE,
  },
  {
    stakeKey: "[bonded coins]",
    stakeValue: "95.4M",
    stakeSrc: WALLET_ALT_IMAGE,
  },
]

const height = 1000
const limit = 20
const offset = 0

const query = `
{
  transaction(
    limit: ${limit}
    offset: ${offset}
    where: { height: { _gte: ${height} } }
    order_by: { height: desc }
  ) {
    hash
    sender
    gas_used
    gas_wanted
    memo
    logs
    fee
    success
    block {
      timestamp
    }
    messages
    height
  }
  transaction_aggregate {
    aggregate {
      count
    }
  }
}`

const options = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },

  body: JSON.stringify({ query }),
}

function StakingOdin() {
  const [validatorsTotal, setValidatorsTotal] = useState()
  const [lastBlock, setLastBlock] = useState()
  const [transactions, setTransactions] = useState()
  const [supply, setSupply] = useState()
  const [bonded, setBonded] = useState()
  const [speed, setSpeed] = useState()
  const [totalPercentage, setTotalPercentage] = useState()
  const [inflation, setInflation] = useState()
  const [apr, setApr] = useState()
  const [isLoading, setIsLoading] = useState()

  useEffect(() => {
    async function fetchData() {
      let totalSupply
      let dataBonded
      let dataInflation
      let blockHeight
      let dataBlockHeight

      // Validators
      try {
        // let reqValidators = await fetch(
        //   "https://api.odinprotocol.io/cosmos/base/tendermint/v1beta1/validatorsets/latest"
        // )
        let reqValidators = await fetch(
          " https://rpc.odinprotocol.io/validators"
        )
        let dataValidators = await reqValidators.json()
        // setValidatorsTotal(dataValidators["pagination"]["total"].toString())
        setValidatorsTotal(dataValidators["result"]["total"].toString())
      } catch (error) {
        console.log("validators ERROR :: ", error)
      }

      // Latest Block
      try {
        let reqLastBlock = await fetch(
          "https://rpc.odinprotocol.io/block?height="
        )
        dataBlockHeight = await reqLastBlock.json()
        blockHeight = dataBlockHeight["result"]["block"]["header"]["height"]
        setLastBlock(dataBlockHeight["result"]["block"]["header"]["height"])
      } catch (error) {
        console.log("abc_info ERROR :: ", error)
      }

      // Transactions
      const url = "https://indexer.odinprotocol.io/v1/graphql"
      try {
        const reqTransactions = await fetch(url, options)
        const responseData = await reqTransactions.json()
        setTransactions(
          responseData["data"]["transaction_aggregate"]["aggregate"]["count"]
        )
        // Handle the response data here
      } catch (error) {
        console.error("Error:", error)
      }

      // Supply
      try {
        let reqSupply = await fetch(
          "https://api.odinprotocol.io/cosmos/bank/v1beta1/supply"
        )
        let dataSupply = await reqSupply.json()

        // take loki and divide it with 1 Milion (1000000)
        totalSupply = dataSupply["supply"].find(sup => sup.denom === "loki")[
          "amount"
        ]
        setSupply((totalSupply / 1000000 / 1000000).toFixed(1))
      } catch (error) {
        console.log("supply ERROR :: ", error)
      }

      // Bonded
      try {
        let reqBonded = await fetch(
          "https://api.odinprotocol.io/cosmos/staking/v1beta1/pool"
        )
        dataBonded = await reqBonded.json()
        setBonded((dataBonded["pool"]["bonded_tokens"] * 1e-12).toFixed(1))
        setTotalPercentage(
          ((dataBonded["pool"]["bonded_tokens"] / totalSupply) * 100).toFixed(2)
        )
      } catch (error) {
        console.log("pool ERROR :: ", error)
      }

      // Speed
      try {
        let reqHeightLess = await fetch(
          "https://rpc.odinprotocol.io/block?height=" + (blockHeight - 1)
        )
        let dataHeightLess = await reqHeightLess.json()

        const time1 = dataBlockHeight["result"]["block"]["header"]["time"]
        const time2 = dataHeightLess["result"]["block"]["header"]["time"]
        let timeStamp1 = new Date(time1)
        let timeStamp2 = new Date(time2)
        const timeDifference = timeStamp1.getTime() - timeStamp2.getTime()
        setSpeed(timeDifference / 1000)
      } catch (error) {
        console.log("block ERROR :: ", error)
      }

      // Inflation
      try {
        // let reqInflation = await fetch(
        //   "https://node.odin-freya-website.odinprotocol.io/mainnet/a/api/mint/parameters"
        // )
        let reqInflation = await fetch(
          " https://api.odinprotocol.io/mint/parameters"
        )
        dataInflation = await reqInflation.json()
        // enable this when we have actual data of inflation
        setInflation((dataInflation["inflation"] * 100).toFixed(2).toString())
      } catch (error) {
        console.log("Inflation ERROR :: ", error)
      }

      //////// APR
      // setApr(
      //   (
      //     (inflation /
      //       (dataBonded["pool"]["bonded_tokens"] /
      //         (totalSupply / 1000000 / 1000000))) *
      //     100
      //   ).toFixed(2)
      // )
      setApr(
        (
          (dataInflation["inflation"] /
            (dataBonded["pool"]["bonded_tokens"] / totalSupply)) *
          100
        ).toFixed(2)
      )

      setIsLoading(false)
    }
    setIsLoading(true)
    fetchData()
  }, [])

  return (
    <section
      className="flex flex-col mx-auto py-24 md:py-40 text-left md:flex-row lg:max-w-full"
      id="staking-odin"
    >
      <div className="w-full">
        <Heading className="text-left font-customHeading sm:leading-normal">
          <span className="reveal-text">
            {"ODIN mainnet Status".split(" ").map((word, i) => (
              <span className="word" key={i}>
                {word}{" "}
              </span>
            ))}
          </span>
        </Heading>
        <div className="md:flex mt-16">
          <div className="w-full md:w-1/4 my-6 md:my-0">
            <a
              href="https://scan.odinprotocol.io/validators"
              target="_blank"
              rel="noopener noreferrer"
              className="border startBtn py-3 px-6 text-xs font-semibold uppercase text-black bg-[yellow] flex items-center justify-between hover:text-[yellow] hover:border-[yellow] hover:bg-black"
              style={{ borderRadius: "53px", height: "41px", width: "170px" }}
            >
              Stake Odin
              <img
                src={RIGHT_ARROW_ICON}
                className="h-5 w-5 ms-3 icon"
                alt=""
              />
              <img
                src={RIGHT_ARROW_HOVER_ICON}
                className="h-5 w-5 ms-3 hoverIcon"
                alt=""
              />
            </a>
          </div>
          <div className="w-full md:w-3/4 grid grid-cols-1 lg:grid-cols-3 gap-8">
            <StakeCard className="p-4 md:py-12 md:px-8">
              <div className="flex lg:flex-col justify-between lg:h-48">
                {/* {stakeDataOne?.map((item, ind) => (
                  <div className="flex" key={ind}>
                    <StakeSpan className="mr-3">{item.stakeValue}</StakeSpan>
                    <StakeP className="opacity-80">{item.stakeKey}</StakeP>
                  </div>
                ))} */}
                <div className="flex">
                  <StakeSpan className="mr-1">
                    {isLoading ? (
                      <BeatLoader color={"#0bae5f"} />
                    ) : totalPercentage ? (
                      `${totalPercentage}%`
                    ) : (
                      "65.71%"
                    )}
                  </StakeSpan>
                  <StakeP className="opacity-80">[bonded coins]</StakeP>
                </div>
                <div className="flex">
                  <StakeSpan className="mr-1">
                    {isLoading ? (
                      <BeatLoader color={"#0bae5f"} />
                    ) : inflation ? (
                      `${inflation}%`
                    ) : (
                      "12%"
                    )}
                  </StakeSpan>
                  <StakeP className="opacity-80">[Inflation]</StakeP>
                </div>
                <div className="flex">
                  <StakeSpan className="mr-1">
                    {isLoading ? (
                      <BeatLoader color={"#0bae5f"} />
                    ) : apr ? (
                      `${apr}%`
                    ) : (
                      "0.00%"
                    )}
                    {/* 0.00% */}
                  </StakeSpan>
                  <StakeP className="opacity-80">[APR]</StakeP>
                </div>
              </div>
            </StakeCard>

            {/* DESKTOP VIEW */}
            <StakeCard className="py-4 px-2 md:py-12 md:px-8 rounded-3xl hidden lg:block w-2/4 md:w-full">
              <div
                className="flex flex-col justify-between"
                style={{ minHeight: "200px" }}
              >
                {/* {stakeDataTwo.map((item, ind) => (
                  <div className="flex" key={ind}>
                    <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                      <img
                        src={item.stakeSrc}
                        alt=""
                        className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                      />
                      {item.stakeValue}
                    </p>
                    <StakeP className="opacity-80" style={{ color: "#fff" }}>
                      {item.stakeKey}
                    </StakeP>
                  </div>
                ))} */}
                <div className="flex">
                  <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                    <img
                      src={BOXES_LIGHT_IMAGE}
                      alt=""
                      className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                    />
                    {isLoading ? (
                      <BeatLoader color={"#0bae5f"} />
                    ) : lastBlock ? (
                      lastBlock
                    ) : (
                      "13417880"
                    )}
                  </p>
                  <StakeP className="opacity-80" style={{ color: "#fff" }}>
                    [latest block]
                  </StakeP>
                </div>
                <div className="flex">
                  <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                    <img
                      src={SORT_ARROW_LIGHT_IMAGE}
                      alt=""
                      className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                    />
                    {isLoading ? (
                      <BeatLoader color={"#0bae5f"} />
                    ) : supply ? (
                      `${supply}M`
                    ) : (
                      "141.5M"
                    )}
                  </p>
                  <StakeP className="opacity-80" style={{ color: "#fff" }}>
                    [Total Supply]
                  </StakeP>
                </div>
                <div className="flex">
                  <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                    <img
                      src={TRANSGER_IMAGE}
                      alt=""
                      className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                    />
                    {isLoading ? (
                      <BeatLoader color={"#0bae5f"} />
                    ) : transactions ? (
                      transactions
                    ) : (
                      "0"
                    )}
                  </p>
                  <StakeP className="opacity-80" style={{ color: "#fff" }}>
                    [transaction(s)]
                  </StakeP>
                </div>
              </div>
            </StakeCard>

            <StakeCard className="py-4 px-1 md:py-12 md:px-8 rounded-3xl hidden lg:block w-2/4 md:w-full">
              <div
                className="flex flex-col justify-between"
                style={{ minHeight: "200px" }}
              >
                {/* {stakeDataThree.map((item, ind) => (
                  <div className="flex" key={ind}>
                    <p className="mr-3 text-sm xl:text-4xl text-[#fff] flex items-center">
                      <img
                        src={item.stakeSrc}
                        alt=""
                        className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                      />
                      {item.stakeValue}
                    </p>
                    <StakeP className="opacity-80" style={{ color: "#fff" }}>
                      {item.stakeKey}
                    </StakeP>
                  </div>
                ))} */}
                <div className="flex">
                  <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                    <img
                      src={SPEED_LIGHT_IMAGE}
                      alt=""
                      className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                    />
                    {/* 2.187s */}
                    {isLoading ? (
                      <BeatLoader color={"#0bae5f"} />
                    ) : speed ? (
                      `${speed}s`
                    ) : (
                      "2.187s"
                    )}
                  </p>
                  <StakeP className="opacity-80" style={{ color: "#fff" }}>
                    [block speed]
                  </StakeP>
                </div>
                <div className="flex">
                  <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                    <img
                      src={GROUP_IMAGE}
                      alt=""
                      className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                    />
                    {isLoading ? (
                      <BeatLoader color={"#0bae5f"} />
                    ) : validatorsTotal ? (
                      validatorsTotal
                    ) : (
                      "34"
                    )}
                  </p>
                  <StakeP className="opacity-80" style={{ color: "#fff" }}>
                    [active validators]
                  </StakeP>
                </div>
                <div className="flex">
                  <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                    <img
                      src={WALLET_ALT_IMAGE}
                      alt=""
                      className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                    />
                    {/* 95.4M */}
                    {isLoading ? (
                      <BeatLoader color={"#0bae5f"} />
                    ) : bonded ? (
                      `${bonded}M`
                    ) : (
                      "95.4M"
                    )}
                  </p>
                  <StakeP className="opacity-80" style={{ color: "#fff" }}>
                    [bonded coins]
                  </StakeP>
                </div>
              </div>
            </StakeCard>

            {/* MOBILE VIEW */}
            <div className="lg:hidden grid grid-cols-2 gap-2 lg:gap-8">
              <StakeCard className="py-4 px-2 md:py-12 md:px-8 rounded-3xl lg:hidden w-full">
                <div
                  className="flex flex-col justify-between"
                  style={{ minHeight: "200px" }}
                >
                  <div className="flex">
                    <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                      <img
                        src={BOXES_LIGHT_IMAGE}
                        alt=""
                        className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                      />
                      {isLoading ? (
                        <BeatLoader color={"#0bae5f"} />
                      ) : lastBlock ? (
                        lastBlock
                      ) : (
                        "13417880"
                      )}
                    </p>
                    <StakeP className="opacity-80" style={{ color: "#fff" }}>
                      [latest block]
                    </StakeP>
                  </div>
                  <div className="flex">
                    <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                      <img
                        src={SORT_ARROW_LIGHT_IMAGE}
                        alt=""
                        className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                      />
                      {isLoading ? (
                        <BeatLoader color={"#0bae5f"} />
                      ) : supply ? (
                        `${supply}M`
                      ) : (
                        "141.5M"
                      )}
                    </p>
                    <StakeP className="opacity-80" style={{ color: "#fff" }}>
                      [Total Supply]
                    </StakeP>
                  </div>
                  <div className="flex">
                    <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                      <img
                        src={TRANSGER_IMAGE}
                        alt=""
                        className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                      />
                      {isLoading ? (
                        <BeatLoader color={"#0bae5f"} />
                      ) : transactions ? (
                        transactions
                      ) : (
                        "0"
                      )}
                    </p>
                    <StakeP className="opacity-80" style={{ color: "#fff" }}>
                      [transaction(s)]
                    </StakeP>
                  </div>
                </div>
              </StakeCard>

              <StakeCard className="py-4 px-2 md:py-12 md:px-8 rounded-3xl lg:hidden w-full flex flex-col justify-between">
                <div
                  className="flex flex-col justify-between"
                  style={{ minHeight: "200px" }}
                >
                  <div className="flex">
                    <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                      <img
                        src={SPEED_LIGHT_IMAGE}
                        alt=""
                        className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                      />
                      {/* 2.187s */}
                      {isLoading ? (
                        <BeatLoader color={"#0bae5f"} />
                      ) : speed ? (
                        `${speed}s`
                      ) : (
                        "2.187s"
                      )}
                    </p>
                    <StakeP className="opacity-80" style={{ color: "#fff" }}>
                      [block speed]
                    </StakeP>
                  </div>
                  <div className="flex">
                    <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                      <img
                        src={GROUP_IMAGE}
                        alt=""
                        className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                      />
                      {isLoading ? (
                        <BeatLoader color={"#0bae5f"} />
                      ) : validatorsTotal ? (
                        validatorsTotal
                      ) : (
                        "34"
                      )}
                    </p>
                    <StakeP className="opacity-80" style={{ color: "#fff" }}>
                      [active validators]
                    </StakeP>
                  </div>
                  <div className="flex">
                    <p className="mr-3 text-sm md:text-3xl xl:text-3xl text-[#fff] flex items-center">
                      <img
                        src={WALLET_ALT_IMAGE}
                        alt=""
                        className="mr-1 md:mr-2 w-4 h-4 md:h-8 md:w-8"
                      />
                      {/* 95.4M */}
                      {isLoading ? (
                        <BeatLoader color={"#0bae5f"} />
                      ) : bonded ? (
                        `${bonded}M`
                      ) : (
                        "95.4M"
                      )}
                    </p>
                    <StakeP className="opacity-80" style={{ color: "#fff" }}>
                      [bonded coins]
                    </StakeP>
                  </div>
                </div>
              </StakeCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StakingOdin
